import React, { Component } from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import styled from "styled-components";
import "../styles/table.css";
import "../styles/CCLandingPage.css";
import "../styles/ServicePage.css";
import "../styles/warm-filter.css";
import Hero from "components/hero/BackgroundAsImage.js";
import HeroR from "components/hero/BackgroundAsImage1.js";
// import LandingPageHero from "components/hero/LandingPageHero.js";
import MainFeature from "components/features/ThreeColSimple.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import Footer from "components/footers/MiniCenteredFooter.js";

import FR1 from "images/fr-1-2.jpg";
import FR2 from "images/fr-2-2.jpg";
import SalesforceLogo from "../images/logo/salesforce.png";
import SAPLogo from "../images/logo/sap.png";

import { SFS, SAP } from "../helpers/Services.js";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import "../styles/alp.css";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-40`}
`;

export default class CCLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  render() {
    const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-75`;
    this.state.width ? <></> : <></>;
    return (
      <div style={{ width: "100vw", overflow: "hidden" }}>
        <div className="hero-container-cc">
          <Hero
            subheading="Welcome to"
            subheading1="CONNECTING CLOUD TECHNOLOGIES"
            heading="Consulting for Every Business"
            buttonText="BOOK A DEMO"
            bgImgURL="../../images/bg/hero-bg.jpg"
          />
        </div>

        <MainFeature />
        <div
          className="spg-container sfs-main"
        >
          {/* <OpacityOverlay /> */}
          <div className="spg-inner">
            <div
              style={{ position: "relative", width: "100vw", height: this.state.width < 1000 ? "max-content" : "100vh" }}
            >
              <div className="spg-inner-flex">
                <center>
                  <img
                    className="spg-img"
                    alt="Salesforce"
                    src={SalesforceLogo}
                  />
                </center>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyItems: "center",
                  }}
                >
                  {SFS.map((item, idx) => {
                    return (
                      <div className="warm-filter" key={idx}>
                        <img
                          className="warm-filter-img"
                          src={
                            item.image
                            // 'https://image.freepik.com/free-photo/hologram-projector-screen-with-cloud-system-technology_53876-108502.jpg?w=740'
                          }
                          alt="background"
                        />
                        <div className="figcaption">
                          <div>
                            <h2 className="card-header-1">{item.title}</h2>
                            <p className="card-header-2">{item.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="spg-container sap-main"
        >
          <div className="spg-inner" >
            <div
              style={{ position: "relative", width: "100vw" }}
            >
              <div className="spg-inner-flex">
                <center>
                  <img className="spg-img-sap" alt="SAP" src={SAPLogo} />
                </center>
                <div
                className="sap-main-content"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  {SAP.map((item, idx) => {
                    return (
                      <div className="warm-filter" key={idx}>
                        <img
                          className="img-sap-bg"
                          src={
                            item.image
                            // "https://image.freepik.com/free-photo/cloud-technology-with-futuristic-hologram-smartwatch_53876-124625.jpg?w=740"
                            // 'https://images.unsplash.com/photo-1605496036006-fa36378ca4ab?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80"'
                          }
                          style={{
                            // backgroundImage: `url('https://image.freepik.com/free-photo/businessman-holding-virtual-infographic-with-human-icons-human-development-recruitment-concept_50039-2612.jpg?w=900')`,
                            height: "100%",
                            width: "100%",
                            // height: "140vh",
                          }}
                          alt="background"
                        />
                        <div className="figcaption">
                          <div>
                            <h2 className="card-header-1">{item.title}</h2>
                            <p className="card-header-2">{item.description}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Container>
          <h5 className="subheading">Technical Services</h5>
          <h1 className="head">CPQ</h1>
          <center className="table-head">
            <table className="styled-table">
              <thead>
                <tr>
                  <th>SAP CPQ</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>✔️ User Managment</td>
                </tr>
                <tr>
                  <td>✔️ Simple Product</td>
                </tr>
                <tr>
                  <td>✔️ Complex Configurable Product Management</td>
                </tr>
                <tr>
                  <td>✔️ Pricing Configuration & Management</td>
                </tr>
                <tr>
                  <td>✔️ Discount and Promotions</td>
                </tr>
                <tr>
                  <td>✔️ Approvals</td>
                </tr>
                <tr>
                  <td>✔️ Workflow</td>
                </tr>
                <tr>
                  <td>✔️ Quote Proposals & Document Generation</td>
                </tr>
                <tr>
                  <td>✔️ Guided Selling</td>
                </tr>
                <tr>
                  <td>✔️ Customer Master Data Replication & Management</td>
                </tr>
                <tr>
                  <td>✔️ Sales Order Generation</td>
                </tr>
              </tbody>
            </table>
            <table className="styled-table">
              <thead>
                <tr>
                  <th>INTEGRATION WITH</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>✔️ Salesforce</td>
                </tr>
                <tr>
                  <td>✔️ SAP Commerce Cloud</td>
                </tr>
                <tr>
                  <td>✔️ SAP Cloud for Customer</td>
                </tr>
                <tr>
                  <td>✔️ SAP S4 HANA</td>
                </tr>
                <tr>
                  <td>✔️ SAP ECC</td>
                </tr>
                <tr>
                  <td>✔️ SAP Subscription billing</td>
                </tr>
                <tr>
                  <td>✔️ SAP Variant Configuration</td>
                </tr>
                <tr>
                  <td>✔️ SAP CPI</td>
                </tr>
                <tr>
                  <td>✔️ DocuSign</td>
                </tr>
                <tr>
                  <td>✔️ AdobeSign</td>
                </tr>
                <tr>
                  <td>✔️ CLM</td>
                </tr>
              </tbody>
            </table>
          </center>
          <DecoratorBlob />
        </Container>
        <Container>
          <ContentWithPaddingXl>
            <div className="tic">
              Features of CPQ
              <div className="tiw">
                <img className="ti1" src={FR1} alt={"feature"} />
                <img className="ti2" src={FR2} alt={"feature"} />
              </div>
            </div>
            <DecoratorBlob />
          </ContentWithPaddingXl>
        </Container> */}
        <Footer />
      </div>
    );
  }
}
