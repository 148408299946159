export const SFS = [
  {
    title: "LWC",
    description:
      "Salesforce Lightning Web Components provides a formulation of such mechanism that makes the development of web components easier, faster and more appropriate.",
    image:
      "https://images.pexels.com/photos/4218883/pexels-photo-4218883.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
  },
  {
    title: "Salesforce Admin",
    description:
      "With the help of a Salesforce Admin, your salespeople can set up personalized dashboards, fields, alerts, and reports to shorten the sales cycle and track every lead in greater detail.",
    image:
      "https://images.unsplash.com/photo-1451187580459-43490279c0fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80",
  },
  {
    title: "SFDC Development",
    description: "It helps businesses identify new opportunities, manage and use customer data, drive marketing campaigns, and improve communications. Ultimately, it helps you drive more robust business growth.",
    image:
      "https://images.unsplash.com/photo-1527600478564-488952effedb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
  },
  {
    title: "Marketing Cloud",
    description:
      "It enables businesses to link up data from various devices, sources, and channels. This provides a more detailed and rounded picture of the customer, their needs, and aspirations.",
    image:
      "https://image.freepik.com/free-photo/cloud-technology-with-futuristic-hologram-smartwatch_53876-124625.jpg?w=740",
  },
  {
    title: "Salesforce CPQ",
    description:
      "CPQ helps sales reps quickly generate complex quotes in seconds. Think of it as increased deal sizes, fewer barriers to closing deals, and the highest possible profit margins.",
    image:
      "https://img.rawpixel.com/s3fs-private/rawpixel_images/website_content/fl37206093391-image-kp4vzq7n.jpg?w=800&dpr=1&fit=default&crop=default&q=65&vib=3&con=3&usm=15&bg=F4F4F3&auto=format&ixlib=js-2.2.1&s=b72ef1a0e2c394fce49bb07c96fc4fec",
  },
  {
    title: "Salesforce FSM",
    description:
      "Field service management helps businesses deliver better service to customers anywhere and from any device.",
      image : 'https://image.freepik.com/free-photo/hologram-projector-screen-with-cloud-system-technology_53876-108502.jpg?w=740'
  },
];
export const SAP = [
  {
    title: "C4C Sales",
    description:
      "SAP Cloud for customer (C4C) is a cloud solution to manage customer sales, customer service and marketing activities efficiently.",
      image : 'https://images.pexels.com/photos/158826/structure-light-led-movement-158826.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  },
  {
    title: "Service Cloud",
    description:
      "It is a comprehensive customer service solution that helps businesses deliver fast and personalised customer service and increase service efficiency.",
      image : 'https://s3.envato.com/files/0cc9ecb1-fb8a-434b-a29f-872ea48f1a29/inline_image_preview.jpg'
  },
  {
    title: "SAP CPQ",
    description: "It is a sales solution that allows sales reps to configure products and services, manage complex pricing structures, and automate the quote and proposal process.",
    image : 'https://images.pexels.com/photos/4665064/pexels-photo-4665064.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  },
  {
    title: "ECC S4HANA",
    description:
      "SAP ECC is the ERP system and SAP HANA is the in-memory database, designed to handle transactions and analytics.",
      image : 'https://ccpglobal.com/wp-content/uploads/2021/04/s4-blog-3-graphic.png'
  },
  {
    title: "SAP ABAP",
    description:
      "SAP Advanced Business Application Programming helps large corporations customize their SAP ERP platform.",
      image : 'https://www.columbusglobal.com/hubfs/Columbus%20Global%20/2019-AMS-Campaign/Importance-of-structure.jpg'
  },
  {
    title: "SAP CPI",
    description:
      "SAP Cloud Platform Integration is a solution for integrating different systems through the cloud. CPI connects systems, services, people, data, AI, IoT",
      image : 'https://images.pexels.com/photos/1150988/pexels-photo-1150988.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  },
  {
    title: "SAP BRIM",
    description:
      "SAP BRIM helps in the management of business model design, it performs contract and order management, billing, and invoice processing and provides flexible options to automate the invoicing process.",
      image : 'https://images.pexels.com/photos/5456314/pexels-photo-5456314.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  },
  {
    title: "SAP SD",
    description:
      "SAP Sales and Distribution allows organizations to store and manage customer- and product-related data.",
      image : 'https://images.pexels.com/photos/986772/pexels-photo-986772.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940'
  },
];

export const OtherTech = [
  {
    title: "Power BI",
    description:
      "Lightning web components are custom HTML elements built using HTML and modern JavaScript.",
  },
  {
    title: "Oracle Apps",
    description:
      "Salesforce Administrators work with stakeholders to define system requirements and customise the platform",
  },
  {
    title: "RPA",
    description: "Complete end to end implementation.",
  },
  {
    title: "Oracle EBS",
    description:
      "Lightning web components are custom HTML elements built using HTML and modern JavaScript.",
  },
];
