import React, { Component } from "react";
import "../../styles/LandingPageHero.css";
import tw from "twin.macro";
import styled from "styled-components";
import Header, {
  NavLink,
  NavLinks,
  // PrimaryLink,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";
import { Link } from "react-router-dom";
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url('../images/LandingBackground.jpg');
`;
// https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80
const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex w-full   items-center block`;
// const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-4xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block  mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;

// const Notification = tw.span`inline-block my-4 pl-3 py-1 text-gray-100 border-l-4 border-blue-500 font-medium text-sm`;

const PrimaryAction = tw.button`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
export default class LandingPageHero extends Component {
  render() {
    const navLinks = [
      <NavLinks key={1}>
        <Link to="/blogs">
          <NavLink>Blogs</NavLink>
        </Link>
        <Link to="/aboutus">
          <NavLink>About</NavLink>
        </Link>
        {/* <NavLink href="#">SAP SERVICES</NavLink> */}
        {/* <NavLink href="#">Locations</NavLink> */}
        {/* <NavLink href="/pricing">Pricing</NavLink> */}
        <Link to="/contactus">
          <NavLink>Contact Us</NavLink>
        </Link>
        <Link to="/scripts">
          <NavLink>Scripts</NavLink>
        </Link>
      </NavLinks>,
      // <NavLinks key={2}>
      //   <PrimaryLink href="/#">Hire Us</PrimaryLink>
      // </NavLinks>,
    ];
    return (
      <div className="outerbox">
        <div className="innerbox">
          <StyledHeader links={navLinks} />
          <TwoColumn>
            <LeftColumn>
              {/* <Notification>
              We have now launched operations in Europe.
            </Notification> */}
              <Heading>
                <SlantedBackground>
                  <span className="slanted">EMPOWER YOUR SALES.</span>
                </SlantedBackground>
                <br />
                <span
                  style={{
                    fontSize: "1.6rem",
                    lineHeight: "2.5rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Start your Configure, Price, Quote journey at predictable time
                  and cost with Connecting Cloud.
                </span>
                <br />
              </Heading>
              <Link to="contactus">
                <PrimaryAction>
                  Contact US{" "}
                  <span role="img" aria-label="contact">
                    ☎️
                  </span>
                </PrimaryAction>
              </Link>
            </LeftColumn>
            {/* <RightColumn>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: growthData,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              height={400}
              width={610}
            />
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
          </RightColumn> */}
          </TwoColumn>
        </div>
      </div>
    );
  }
}
